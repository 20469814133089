import icoeBaseButtons from './baseButtons.html'
import icoeFields from './fields.html'

const EMPTY_ICOE_ITEMS = { "name": "", "contact": "", "link": "" };
var icoeItems = [];
var newIcoeInput = $( '<input type="hidden" />' );

var isIcoeItemEmpty = function(icoeItem) {
  return icoeItem.name === "" &&
    icoeItem.contact === "" &&
    icoeItem.link === "";
};

var computeIcoeValue = function() {
  if (icoeItems.length === 0)
    $( "div#noContacts" ).removeClass('d-none');
  else
    $( "div#noContacts" ).addClass('d-none');


  while( $( "#icoe_fields_wrapper" ).children().length > icoeItems.length ) {
    $( "#icoe_fields_wrapper" ).children().last().remove();
  }

  icoeItems.forEach(function(item, index) {
    if ($( "#icoe_fields_wrapper" ).children().length <= index)
    {
      $( "#icoe_fields_wrapper" ).append( $( icoeFields ) );
    }

    var currentChild = $( "#icoe_fields_wrapper" ).children().eq(index);
    setupIcoeFieldsItem(currentChild, index);
  });

  newIcoeInput.val(JSON.stringify(icoeItems));
};

var setupIcoeFieldsItem = function(currentItem, index) {
  ['name', 'contact', 'link'].forEach(function(fieldTypeName) {
    var field = currentItem.find( 'input.identity_icoe_' + fieldTypeName).eq(0);
    field.attr('id', 'identity_icoe_' + fieldTypeName + '_' + index);
    field.val(icoeItems[index][fieldTypeName]);
    field.off( 'change' );
    field.on( 'change', function() {
      icoeItems[index][fieldTypeName] = this.value;
      computeIcoeValue();
    });
  });

  var removeContactButton = currentItem.find( "#removeContactButton" );
  removeContactButton.off( 'click' );
  removeContactButton.on('click', function() {
    removeContact(index);
  });
};

var addContact = function() {
  if (icoeItems.length > 0 &&
      isIcoeItemEmpty(icoeItems[icoeItems.length - 1]))
    return;

  var clonedobj = jQuery.extend({}, EMPTY_ICOE_ITEMS)
  icoeItems.push(clonedobj);

  computeIcoeValue();
};

var removeContact = function(index) {
  icoeItems.splice(index, 1);

  computeIcoeValue();
};

var makeIcoeForm = function( options ) {
  // This is the easiest way to have default options.
  var settings = $.extend({
    // These are the defaults.
    // color: "#556b2f",
    // backgroundColor: "white"
  }, options );

  icoeItems = JSON.parse(this.val());

  $.each(this.get(0).attributes, function(i, attrib) {
    newIcoeInput.attr(attrib.name, attrib.value);
  });

  $( this ).replaceWith( newIcoeInput );
  $( icoeBaseButtons ).insertAfter(newIcoeInput);

  $( "#addContactButton" ).on('click', function(event) {
    addContact();
  });

  computeIcoeValue();

  return this;
};

export default makeIcoeForm;
