var fillOtherInput = function( targetInput ) {
  var sourceInput = $( this );
  sourceInput.on( 'change keypress keyup', function() {
    targetInput.val( sourceInput.val() )
  });

  targetInput.on( 'change keypress keyup', function() {
    sourceInput.off( 'change keypress keyup' );
  });
};

export default fillOtherInput;
