var previousValues = {};

var applyShippingInfoToBilling = function(checked, settings) {
  settings.form.find( "input[id^=" + settings.billingPrefix + "], select[id^=" + settings.billingPrefix + "]" ).each(function(i, item) {
    var raw_item_id = item.id.replace(settings.billingPrefix, '')
                             .replace(/^_/, '');;

    if (checked)
    {
      previousValues[raw_item_id] = $( item ).val();

      var shippingItem = $( "#" + settings.shippingPrefix + "_" + raw_item_id );
      if ( shippingItem.length === 0 )
        return;

      $( item ).val( shippingItem.val() );
    }
    else
    {
      $( item ).val( previousValues[raw_item_id] || "");
    }
    var disabledItem = $( "#" + item.id + "_disabled" );
    disabledItem.val( $( item ).val() );

    makeItemReadOnly(item, checked);
  });
};

var makeItemReadOnly = function( item, readonly ) {
  var jItem = $( item );
  if ( jItem.is( 'select' ) ) {
    var originalId = null;
    if ( jItem.data( 'original-id' ) === undefined )
      originalId = jItem.attr( 'id' );
    else
      originalId = jItem.data( 'original-id' );

    var hiddenInput = $( 'input#' + originalId );

    if ( readonly )
    {
      // We should change select id and create a hidden input
      if (readonly && hiddenInput.length === 0)
      {
        hiddenInput = $( '<input type="hidden" />' );
      }
      $.each(jItem.get(0).attributes, function(i, attrib){
        if ( attrib.name === 'disabled' || attrib.name === 'class' || attrib.name === 'id' )
          return;
        hiddenInput.attr(attrib.name, jItem.attr( attrib.name ) );
      });
      hiddenInput.val( jItem.val() );
      hiddenInput.attr( 'id', originalId );
      jItem.attr( 'id', originalId + '_disabled' );
      jItem.data( 'original-id', originalId );
      jItem.attr( 'disabled', true );
      hiddenInput.insertAfter( jItem );
    }
    else {
      // We should remove the hidden input and assign back the select original id
      hiddenInput.remove();
      jItem.attr( 'id', originalId );
      jItem.data( 'original-id', undefined );
      jItem.attr( 'disabled', false );
    }


  } else {
    jItem.prop('readonly', readonly);
  }
};

var populatePreviousValues = function(settings) {
  settings.form.find( "input[id^=" + settings.billingPrefix + "], select[id^=" + settings.billingPrefix + "]" ).each(function(i, item) {
    var raw_item_id = item.id.replace(settings.billingPrefix, '')
                             .replace(/^_/, '');

    previousValues[raw_item_id] = $( item ).val();
  });
};

var useShippingInfo = function( options ) {
  // This is the easiest way to have default options.
  var settings = $.extend({
    // These are the defaults.
    // color: "#556b2f",
    // backgroundColor: "white"
    form: null,
    shippingPrefix: 'order_shipping',
    billingPrefix: 'order_billing'
  }, options );

  var innerThis = this.get(0);

  populatePreviousValues(settings);

  this.on('change', function() {
    applyShippingInfoToBilling(innerThis.checked, settings);
  });
  applyShippingInfoToBilling(innerThis.checked, settings);

  settings.form.find( "input[id^=" + settings.shippingPrefix + "], select[id^=" + settings.shippingPrefix + "]" ).each(function(i, item) {
    $( '#' + item.id ).on('change keypress keyup', function() {
      applyShippingInfoToBilling(innerThis.checked, settings);
    });
  });

  return this;
};

export default useShippingInfo;
