import modalDom from './modal-dom.html'
import imgInputDom from './img-input.html'
import spinnerSvg from './spinner.html'
import PU_CONSTANTS from './constants.js.erb'
import jQueryCropper from 'jquery-cropper/dist/jquery-cropper.esm.js';

const MODAL_SEL = '#photo-upload-modal';
const IMG_INPUT_SEL = '#photo-uploader-img-input';
const IMG_PREVIEW_SEL = '#photo-upload-modal-img-preview';
const VALIDATE_CROP_BUTTON_SEL = '#photo-upload-modal-validate';

class PhotoUploader {
  constructor(options) {
    this._options = options;

    this._uploadButton = null;
    this._modal = null;
    this._imgInput = null;
    this._cropper = null;
    this._validateCropButton = null;
  }
  get uploadButton() {
    return this._uploadButton;
  }

  set uploadButton(uploadButton) {
    this._uploadButton = uploadButton;
  }

  prepareDOM() {
    this.buildInputImg();
    this.buildModal();

    var innerThis = this;
    this._imgInput.on("change", function() {
      var selectedImage = $(this).get(0);
      $( MODAL_SEL ).on('shown.bs.modal', function (event) {
        innerThis.loadSelectedImage(selectedImage);

        $( VALIDATE_CROP_BUTTON_SEL ).on('click', function() {
          innerThis.uploadCroppedImage();
        });
      });
      $( MODAL_SEL ).modal({
        backdrop: 'static',
        keyboard: false
      });
    });
    this._uploadButton.on('click', function() {
      $( IMG_INPUT_SEL ).click();
    });
    this.buildUploadingSvg();
    this.hideModalImg();
  }

  buildUploadingSvg() {
    $( MODAL_SEL ).find( "#modal-footer-uploading" ).html( $( spinnerSvg ) );
  }

  hideUploadingSvg() {
    $( MODAL_SEL ).find( "#modal-footer-uploading" ).addClass( 'd-none' );
  }

  showUploadingSvg() {
    $( MODAL_SEL ).find( "#modal-footer-uploading" ).removeClass( 'd-none' );
  }

  showError(text) {
    $( MODAL_SEL ).find( "#modal-footer-error" ).removeClass( 'd-none' );
    $( MODAL_SEL ).find( "#modal-footer-error > p#modal-footer-error-detail" ).html( text );
  }

  hideError() {
    $( MODAL_SEL ).find( "#modal-footer-error" ).addClass( 'd-none' );
  }

  hideModalImg() {
    $( MODAL_SEL ).find( "#modal-body-img" ).addClass( 'd-none' );
  }

  showModalImg() {
    $( MODAL_SEL ).find( "#modal-body-img" ).removeClass( 'd-none' );
  }

  loadSelectedImage(input) {
    var innerThis = this;
    if (input.files && input.files[0]) {
      var reader = new FileReader();

      reader.onload = function(e) {
        $( IMG_PREVIEW_SEL ).attr('src', e.target.result);
        $( IMG_PREVIEW_SEL ).on('load', function() {
          innerThis.loadCropper();
        });
      }

      innerThis._imageFile = input.files[0]
      reader.readAsDataURL(innerThis._imageFile);
    }
  }

  loadCropper() {
    var innerThis = this;
    var cropperWidth = $( MODAL_SEL ).find( "#modal-body-wrapper" ).width() - 16 * 2;
    var imgRatio = $( IMG_PREVIEW_SEL ).get(0).naturalWidth / $( IMG_PREVIEW_SEL ).get(0).naturalHeight;
    this._cropper = $( IMG_PREVIEW_SEL ).cropper({
      autoCrop: true,
      aspectRatio: PU_CONSTANTS.ASPECT_RATIO,
      minContainerWidth: cropperWidth,
      minContainerHeight: cropperWidth / imgRatio,
      ready: function(event) {
        innerThis.showModalImg();
        innerThis.hideUploadingSvg();
      }
    });
  }

  uploadCroppedImage() {
    this.showUploadingSvg();
    var innerThis = this;
    $( IMG_PREVIEW_SEL ).cropper('getCroppedCanvas').toBlob(function (blob) {
      var formData = new FormData();
      formData.append('identity[photo]', blob, innerThis._imageFile.name);

      $.ajax(innerThis._options.upload_url, {
        method: "POST",
        data: formData,
        processData: false,
        contentType: false
      })
        .done(function (data) {
          location.reload();
        })
        .fail(function (jqXHR, textStatus, errorThrown) {
          var message = textStatus;
          if (jqXHR.responseJSON && jqXHR.responseJSON.errors)
            message = jqXHR.responseJSON.errors.join('<br />');
          innerThis.showError(message);
        })
        .always(function() {
          innerThis.hideUploadingSvg();
        });
    }, innerThis._imageFile.type);
  }

  buildInputImg() {
    this._imgInput = $( imgInputDom );
    this._imgInput.attr('accept', PU_CONSTANTS.ACCEPTED_EXTENSIONS);

    this._imgInput.insertBefore(this._uploadButton);
  }

  buildModal() {
    this._modal = $( modalDom );

    this._modal.insertAfter(this._uploadButton);
  }
}

export default PhotoUploader;
