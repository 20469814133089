import Util from 'bootstrap/js/src/util'

const ClassName = {
  BANNER : 'banner',
  FADE  : 'fade',
  SHOW  : 'show'
}

var _handleDismiss = function( element ) {

  $( element ).on('click', function(event) {

    var target = $( this ).closest( '.' + ClassName.BANNER );

    target.removeClass(ClassName.SHOW)

    if (!target.hasClass(ClassName.FADE)) {
      _destroyElement(target);
      return;
    }

    const transitionDuration = Util.getTransitionDurationFromElement( target )

    $( target )
      .one(Util.TRANSITION_END, (event) => _destroyElement(target, event))
      .emulateTransitionEnd(transitionDuration)
  });
}

var _destroyElement = function(element) {
  $(element)
    .detach()
    .remove()
};

$(document).ready( function() {
  _handleDismiss($( '[data-dismiss="' + ClassName.BANNER + '"]' ));
})
