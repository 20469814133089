import PhotoUploader from './photo-uploader.js'

var attachPhotoUploader = function( options ) {
  // This is the easiest way to have default options.
  var settings = $.extend({
    // These are the defaults.
    // color: "#556b2f",
    // backgroundColor: "white"
    upload_url: '/',
  }, options );

  var pu = new PhotoUploader(settings);
  pu.uploadButton = this;
  pu.prepareDOM();

  return this;
};

export { attachPhotoUploader };
