// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

var Rails = require("@rails/ujs");
var Turbolinks = require("turbolinks");
var ActiveStorage = require("@rails/activestorage");
require("channels");
require("bootstrap");
var CookiesEuBanner = require('cookies-eu-banner');
var photoupload = require('src/photoupload/');
var useShippingInfo = require('src/use_shipping_info');
var fillOtherInput = require('src/fill_other_input');
var makeIcoeForm = require('src/icoe_form');
var banner = require('src/banner');

// https://stackoverflow.com/questions/55895494/is-not-defined-when-installing-jquery-in-rails-via-webpack
window.jQuery = $;
window.$ = $;
window.$.fn.attachPhotoUploader = photoupload.attachPhotoUploader;
window.$.fn.useShippingInfo = useShippingInfo.default;
window.$.fn.fillOtherInput = fillOtherInput.default;
window.$.fn.makeIcoeForm = makeIcoeForm.default;

window.CookiesEuBanner = CookiesEuBanner;

Rails.start();
Turbolinks.start();
ActiveStorage.start();

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
